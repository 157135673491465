<template>
  <div>
    <form-builder :schema="schema" />

    <div class="text-center q-pa-xs border-top">
      <q-btn
        color="dark"
        text-color="white"
        size="sm"
        :label="$t('Discard')"
        class="q-mr-sm"
        no-caps
        unelevated
        @click="handleBack"
      />

      <q-btn
        color="light-blue-9"
        text-color="white"
        size="sm"
        :label="$t('Save')"
        no-caps
        unelevated
        @click="save"
      />
    </div>
  </div>
</template>

<script>
// Utils
import { convertEmbedded, difference } from '../../helpers/request-helpers'
import _ from 'lodash'

// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'NotificationChannelPanel',
  props: {
    model: {
      type: [Object, null],
      default () {
        return null
      }
    }
  },
  data () {
    return {
      types: [
        { id: 'call', name: this.$t('Call') },
        { id: 'email', name: this.$t('Email') },
        { id: 'sms', name: this.$t('SMS') },
        { id: 'ivr', name: this.$t('IVR') },
        { id: 'public', name: this.$t('Public') },
        { id: 'private', name: this.$t('Private') }
      ],
      states: [
        { id: 'active', name: this.$t('Active') },
        { id: 'inactive', name: this.$t('Inactive') },
        { id: 'deleted', name: this.$t('Deleted') }
      ],
      access: [
        { id: 'public', name: this.$t('Public') },
        { id: 'private', name: this.$t('Private') }
      ],
      handlers: [
        { id: 'asterisk', name: this.$t('Asterisk') },
        { id: 'rapporto', name: this.$t('Rapporto') },
        { id: 'bots', name: this.$t('Bots') },
        { id: 'email', name: this.$t('Email') },
        { id: 'postback', name: this.$t('Postback') },
        { id: 'telefonico', name: this.$t('Telefonico') }
      ],
      channel: {
        access: null,
        handler: null,
        name: null,
        state: 'active',
        type: null
      },
      cleanChannel: null
    }
  },
  computed: {
    ...mapGetters([
      'isSupervisior',
      'isAdministrator'
    ]),
    schema () {
      return {
        isLoading: !this.channel,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Name'),
                field: 'name',
                value: this.channel && this.channel.name,
                required: true,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: name => {
                  this.updateChannel({ name })
                }
              },
              {
                type: 'select',
                label: this.$t('Handler'),
                field: 'handler',
                value: this.channel && this.channel.handler,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                options: this.handlers,
                customLabel (row) {
                  return row && typeof row === 'object'
                    ? row.name
                    : row
                },
                onChange: (handler) => {
                  this.updateChannel({ handler: handler.id })
                }
              },
              {
                type: 'select',
                label: this.$t('Status'),
                field: 'state',
                value: this.channel && this.channel.state,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                options: this.states,
                customLabel (row) {
                  return row && typeof row === 'object'
                    ? row.name
                    : row
                },
                onChange: (state) => {
                  this.updateChannel({ state: state.id })
                }
              },
              {
                type: 'select',
                label: this.$t('Type'),
                field: 'type',
                value: this.channel && this.channel.type,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                options: this.types,
                customLabel (row) {
                  return row && typeof row === 'object'
                    ? row.name
                    : row
                },
                onChange: (type) => {
                  this.updateChannel({ type: type.id })
                }
              },
              {
                type: 'select',
                label: this.$t('Access Type'),
                field: 'access',
                value: this.channel && this.channel.access,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                options: this.access,
                customLabel (row) {
                  return row && typeof row === 'object'
                    ? row.name
                    : row
                },
                onChange: (access) => {
                  this.updateChannel({ access: access.id })
                }
              },
              {
                ref: 'owner',
                if: this.isAdministrator || this.isSupervisior,
                type: 'multiselect',
                label: this.$t('Owner'),
                value: this.channel && this.channel.owner,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-sm',
                required: true,
                field: 'owner',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: []
                  }

                  return this.$service.user.getAll(query)
                },
                onChange: (owner) => {
                  this.updateChannel({ owner })
                }
              },
              ...Object.keys(((this.channel || {}).settings || {})).map(key => {
                const label = key[0].toUpperCase() + key.slice(1)

                return {
                  type: 'input',
                  inputType: 'text',
                  label: this.$t(label.split('-').join(' ')),
                  field: key,
                  value: this.channel.settings[key],
                  wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                  onChange: value => {
                    this.updateChannel({ settings: { ...this.channel.settings, [key]: value } })
                  }
                }
              })
            ]
          }
        ]
      }
    }
  },
  watch: {
    model (value) {
      this.loadChannel(value)
    }
  },
  mounted () {
    this.loadChannel(this.model)
  },
  methods: {
    loadChannel (model) {
      if (model) {
        this.channel = _.cloneDeep(model)

        if (this.channel.id) {
          this.cleanChannel = _.cloneDeep(model)
        }

        return
      }

      this.channel = {
        access: null,
        handler: null,
        name: null,
        state: 'active',
        type: null
      }

      this.cleanChannel = null
    },
    updateChannel (update) {
      this.channel = {
        ...this.channel,
        ...update
      }
    },
    updateChannelEmbedded (update) {
      this.channel = {
        ...this.channel,
        _embedded: {
          ...this.channel._embedded,
          ...update
        }
      }
    },
    handleBack () {
      this.$emit('cancel')
    },
    save () {
      const ignoredFields = []
      const data = this.channel.id
        ? convertEmbedded(difference(this.channel, this.cleanChannel), ignoredFields)
        : convertEmbedded(this.channel)

      if (Object.keys(data).length === 0) {
        return Promise.resolve(this.channel)
      }

      return this.$service.notificationChannel.save(data, this.channel.id)
        .then(channel => {
          this.$emit('submit', channel)
        })
    }
  }
}
</script>
