<template>
  <div>
    <form-builder :schema="schema" />

    <material-editor
      :keywords="keywords"
      :model-value="template.template"
      style="min-height: 300px;"
      @update:model-value="updateTemplate({ template: $event })"
    />

    <div class="text-center q-pa-xs border-top">
      <q-btn
        color="dark"
        text-color="white"
        size="sm"
        :label="$t('Discard')"
        class="q-mr-sm"
        no-caps
        unelevated
        @click="handleBack"
      />

      <q-btn
        color="light-blue-9"
        text-color="white"
        size="sm"
        :label="$t('Save')"
        no-caps
        unelevated
        @click="save"
      />
    </div>
  </div>
</template>

<script>
// Utils
import { convertEmbedded, difference } from '../../helpers/request-helpers'
import _ from 'lodash'

// Components
import MaterialEditor from '../editor/MaterialEditor.vue'

export default {
  name: 'NotificationTemplatePanel',
  components: {
    MaterialEditor
  },
  props: {
    keywords: {
      type: Array,
      default () {
        return []
      }
    },
    model: {
      type: [Object, null],
      default () {
        return null
      }
    }
  },
  data () {
    return {
      template: {
        name: null,
        state: 'active',
        event: null,
        template: null
      },
      cleanTemplate: null
    }
  },
  computed: {
    schema () {
      return {
        isLoading: !this.template,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Name'),
                field: 'name',
                value: this.template && this.template.name,
                required: true,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: name => {
                  this.updateTemplate({ name })
                  this.hasChange = true
                }
              }
            ]
          }
        ]
      }
    }
  },
  watch: {
    model (value) {
      this.loadTemplate(value)
    }
  },
  mounted () {
    this.loadTemplate(this.model)
  },
  methods: {
    loadTemplate (model) {
      if (model) {
        this.template = _.cloneDeep(model)

        if (this.template.id) {
          this.cleanTemplate = _.cloneDeep(model)
        }

        return
      }

      this.template = {
        name: null,
        state: 'active',
        event: null,
        template: null
      }

      this.cleanTemplate = null
    },
    updateTemplate (update) {
      this.template = {
        ...this.template,
        ...update
      }
    },
    save () {
      const ignoredFields = ['channel']
      const data = this.template.id
        ? convertEmbedded(difference(this.template, this.cleanTemplate), ignoredFields)
        : convertEmbedded(this.template)

      if (Object.keys(data).length === 0) {
        return Promise.resolve(this.template)
      }

      return this.$service.notificationTemplate.save(data, this.template.id)
        .then(channel => {
          this.$emit('submit', channel)
        })
    },
    handleBack () {
      this.$emit('cancel')
    }
  }
}
</script>
